import { ComponentRef, Directive, DoCheck, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { ScrollButtonComponent } from '../../component/scroll-button/scroll-button.component';

@Directive({
  selector: '[appScrollWithWheel]',
  standalone: true,
})
export class ScrollWithWheelDirective implements DoCheck {
  @Input() public scrollButtonColor?: string;
  @Input() public scrollDirection: 'x' | 'y' = 'x';
  private componentLeft?: ComponentRef<ScrollButtonComponent> | null;
  private componentRight?: ComponentRef<ScrollButtonComponent> | null;
  private componentTop?: ComponentRef<ScrollButtonComponent> | null;
  private componentBottom?: ComponentRef<ScrollButtonComponent> | null;

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngDoCheck(): void {
    this.scrollOptions();
  }

  @HostListener('wheel', ['$event'])
  public onScroll(event: WheelEvent): void {
    const nativeElement = this.element?.nativeElement as HTMLElement;
    if (!nativeElement) {
      return;
    }
    if (this.scrollDirection === 'x') {
      this.onScrollX(event, nativeElement);
    }

    if (this.scrollDirection === 'y') {
      this.onScrollY(event, nativeElement);
    }
  }

  @HostListener('touchmove')
  public onTouchmove(): void {
    this.scrollOptions();
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.scrollOptions();
  }

  private onScrollX(event: WheelEvent, nativeElement: HTMLElement): void {
    if (nativeElement.scrollWidth <= nativeElement.clientWidth) {
      return;
    }
    event.preventDefault();
    this.scrollLeft(event.deltaY);

    this.scrollOptions();
  }

  private onScrollY(event: WheelEvent, nativeElement: HTMLElement): void {
    if (nativeElement.scrollHeight <= nativeElement.clientHeight) {
      return;
    }

    this.scrollOptions();
  }

  private scrollLeft(delta: number): void {
    const nativeElement = this.element?.nativeElement;
    if (!nativeElement) {
      return;
    }
    if (nativeElement.scrollWidth <= nativeElement.clientWidth) {
      return;
    }
    nativeElement.scrollLeft += Math.min(delta, nativeElement.clientWidth);
  }

  private scrollOptions(): void {
    const nativeElement = this.element?.nativeElement as HTMLElement;
    if (!nativeElement) {
      return;
    }
    if (this.scrollDirection === 'x') {
      this.scrollOptionsX(nativeElement);
    }
    if (this.scrollDirection === 'y') {
      this.scrollOptionsY(nativeElement);
    }
  }

  private scrollOptionsX(nativeElement: HTMLElement): void {
    const rightDist = nativeElement.scrollWidth - nativeElement.clientWidth;

    const left = nativeElement.scrollLeft > 0;
    const right = Math.abs(nativeElement.scrollLeft - rightDist) >= 2;

    if (left && !this.componentLeft) {
      this.componentLeft = this.createScrollButton('left');
      nativeElement.appendChild(this.componentLeft.location.nativeElement);
    }

    if (!left && this.componentLeft) {
      this.componentLeft.destroy();
      this.componentLeft = null;
    }

    if (right && !this.componentRight) {
      this.componentRight = this.createScrollButton('right');
      nativeElement.appendChild(this.componentRight.location.nativeElement);
    }

    if (!right && this.componentRight) {
      this.componentRight.destroy();
      this.componentRight = null;
    }
  }

  private scrollOptionsY(nativeElement: HTMLElement) {
    const top = nativeElement.scrollTop > 0;
    const bottom = nativeElement.scrollHeight - (nativeElement.clientHeight + nativeElement.scrollTop) > 2;

    if (top && !this.componentTop) {
      this.componentTop = this.createScrollButton('top');
      nativeElement.appendChild(this.componentTop.location.nativeElement);
    }

    if (!top && this.componentTop) {
      this.componentTop.destroy();
      this.componentTop = null;
    }
    if (bottom && !this.componentBottom) {
      this.componentBottom = this.createScrollButton('bottom');
      nativeElement.appendChild(this.componentBottom.location.nativeElement);
    }

    if (!bottom && this.componentBottom) {
      this.componentBottom.destroy();
      this.componentBottom = null;
    }
  }

  private createScrollButton(mode: 'left' | 'right' | 'top' | 'bottom'): ComponentRef<ScrollButtonComponent> {
    const componentComponentRef = this.viewContainerRef.createComponent(ScrollButtonComponent);
    const instance = componentComponentRef.instance;
    instance.mode = mode;
    if (this.scrollButtonColor) {
      instance.solidColor = this.scrollButtonColor;
    }
    return componentComponentRef;
  }
}
